<template>
<div class="markdown-container" v-html="htmlContent" />
</template>

<script>
import * as showdown from 'showdown';

export default {
  name: 'markdown',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    htmlContent() {
      showdown.extension('targetlink', () => (
        [{
          type: 'html',
          regex: /(<a [^>]+?)(>.*<\/a>)/g,
          replace: '$1 target="_blank"$2',
        }]
      ));
      const converter = new showdown.Converter({
        strikethrough: true,
        extensions: ['targetlink'],
      });
      return converter.makeHtml(this.content);
    },
  },
};
</script>

<style lang="scss">

.markdown-container {
  font-family: $font-normal;
  line-height: 24px;
  font-size: 16px;
  color: $main-black;

  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 24px;
    margin-top: 40px;
  }
  h3 {
    margin: 32px 0 8px;
  }
  h5 {
    font-size: 14px;
    margin: 8px 0;
  }
  p {
    margin: 16px 0;
    font-size: 18px;
  }
  img {
    width: 100%;
    margin: 24px 0;
  }
  a {
    color: #0433ff;
  }
}
</style>
